import React from "react";
import styled from "styled-components";
import {BROWN} from "./colors";
import { getI18n } from "react-i18next";

const FontBaseStyle = styled.p`
  font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 0;
  color: ${props => (props.color ? props.color : BROWN)};
`;

export const FontBase = props => {
  return <FontBaseStyle {...props}>{typeof props.children === "string" ? `${getI18n().t(props.children)}` : props.children}</FontBaseStyle>;
};

export const TitleBig = styled(FontBase)`
  font-weight: 600;
  font-size: 24px;

  @media (max-width: 700px), (max-height: 700px) {
    font-size: 18px;
  }
`;

export const Title = styled(FontBase)`
  font-weight: 700;
  font-size: 16px;

  @media (max-width: 700px), (max-height: 700px) {
    font-size: 14px;
  }
`;

export const TitleSmall = styled(FontBase)`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${props => (props.color ? props.color : BROWN)};

  @media (max-width: 700px), (max-height: 700px) {
    font-size: 12px;
  }
`;

export const Text = styled(FontBase)`
  color: ${props => (props.color ? props.color : BROWN)};
  font-size: 14px;
  line-height: 21px;
  white-space: pre-wrap;

  @media (max-width: 700px), (max-height: 700px) {
    font-size: 12px;
  }
`;

export const TextSmall = styled(FontBase)`
  color: ${props => (props.color ? props.color : BROWN)};
  font-size: 12px;
  line-height: 18px;

  @media (max-width: 700px), (max-height: 700px) {
    font-size: 11px;
  }
`;
