import React, { useState } from "react";
import styled from "styled-components";
import BooleanRadioWidget from "./BooleanRadioWidget";
import { Text, FontBase } from "../../../styles/fonts";
import { PINK } from "../../../styles/colors";

const checkboxEntries = [
  "EU/EØS eller UK",
  "USA",
  "Afghanistan",
  "Albania",
  "Bahamas",
  "Barbados",
  "Bosnia-Hercegovina",
  "Botswana",
  "Etiopia",
  "Ghana",
  "Guyana",
  "Irak",
  "Iran",
  "Island",
  "Jamaica",
  "Jemen",
  "Kambodsja",
  "Laos",
  "Mauritius",
  "Mongolia",
  "Myanmar",
  "Nicaragua",
  "Nord-Korea",
  "Pakistan",
  "Panama",
  "Sri Lanka",
  "Syria",
  "Trinidad og Tobago",
  "Tunisia",
  "Uganda",
  "Vanuatu",
  "Zimbabwe",
  "Andre land",
];

type ZtlCustomProps = {
  onChange: (d: FormData) => void;
  formData: FormData;
  rawErrors?: Array<string>;
  uiSchema?: any;
  errorSchema?: any;
};

type FormData = {
  checked: boolean | null;
  countries: Array<TransferToCountry>;
  atleastOneCountrySelected?: boolean;
  otherCountrySelected?: boolean;
  otherTransferCountry?: string;
  otherTransferCurrency?: string;
  otherTransferProducts?: string;
};

type TransferToCountry = {
  country: string;
  willTransfer: string;
};

const allCountriesFalse = checkboxEntries.map((el) => ({
  country: el,
  willTransfer: "false",
}));

const initialFormData: FormData = {
  checked: null,
  countries: allCountriesFalse,
};
let newFormData: FormData = initialFormData;

function handleCheckboxClick(
  checked: boolean,
  cntry: string,
  formData: FormData,
  onChange: (d: FormData) => void,
  otherTransferCountry: string,
  otherTransferCurrency: string,
  otherTransferProducts: string
) {
  const countriesWithoutCurrentCountry: Array<TransferToCountry> =
    formData.countries.filter((el) => el.country !== cntry);
  const newCountries: Array<TransferToCountry> = [
    ...countriesWithoutCurrentCountry,
    { country: cntry, willTransfer: JSON.stringify(checked) },
  ];
  let oneCountrySelected = newCountries.filter(
    (el) => el.willTransfer === "true"
  );
  let otherCountrySelected = newCountries
    .filter((el) => el.willTransfer === "true")
    .find((el) => el.country === "Andre land");
  newFormData = {
    checked: formData.checked,
    countries: newCountries,
    ...(oneCountrySelected.length !== 0
      ? { atleastOneCountrySelected: true }
      : {}),
    otherCountrySelected: !!otherCountrySelected,
    ...(cntry === "Andre land" && checked ? { otherTransferCountry } : {}),
    ...(cntry === "Andre land" && checked ? { otherTransferCurrency } : {}),
    ...(cntry === "Andre land" && checked ? { otherTransferProducts } : {}),
  };
  onChange(newFormData);
}

function ZtlCustomCountryPicker(props: ZtlCustomProps) {
  const [otherTransferCountry, setOtherTransferCountry] = useState();
  const [otherTransferCurrency, setOtherTransferCurrency] = useState();
  const [otherTransferProducts, setOtherTransferProducts] = useState();
  const errors = props.errorSchema;
  const errorNames = Object.keys(errors || {});
  const formData = props.formData || initialFormData;
  return (
    <div>
      <BooleanRadioWidget
        onChange={(v) => {
          props.onChange({
            countries: v ? formData.countries : allCountriesFalse,
            checked: v,
            ...(!v ? { atleastOneCountrySelected: false } : {}),
            otherCountrySelected: false,
          });
        }}
        value={formData.checked}
      />
      {formData.checked ? (
        <div style={{ marginLeft: 30, marginTop: 5, marginRight: 20 }}>
          <Title error={errorNames.includes("atleastOneCountrySelected")}>
            Hvor?
          </Title>
          {errorNames.includes("atleastOneCountrySelected") ? (
            <ErrorText>Please select atleast one country</ErrorText>
          ) : (
            ""
          )}
          <MultiSelectFrame>
            {checkboxEntries.map((cntry) => (
              <div key={cntry} style={{ display: "flex" }}>
                <StyledCheckbox
                  type="checkbox"
                  onChange={(e) => {
                    handleCheckboxClick(
                      e.target.checked,
                      cntry,
                      formData,
                      props.onChange,
                      otherTransferCountry,
                      otherTransferCurrency,
                      otherTransferProducts
                    );
                  }}
                />
                <Text>{cntry}</Text>
              </div>
            ))}
            {formData.otherCountrySelected && (
              <AdditionalQuestionsOther>
                <Title error={errorNames.includes("otherTransferCountry")}>
                  Hvilke land forventes det å gjøre betalinger til?
                </Title>
                <Input
                  value={otherTransferCountry}
                  onChange={(e) => setOtherTransferCountry(e.target.value)}
                  onBlur={(e) => {
                    newFormData = {
                      ...newFormData,
                      otherTransferCountry: e.target.value,
                    };
                    if (e.target.value === "") {
                      delete newFormData.otherTransferCountry;
                    }
                    props.onChange(newFormData);
                    setOtherTransferCountry(e.target.value);
                  }}
                />
                <Title
                  error={errorNames.includes("otherTransferProducts")}
                >
                  Hvilke produkter/tjenester betales det for ved
                  utenlandsbetalinger?
                </Title>
                <Input
                  value={otherTransferProducts}
                  onChange={(e) => setOtherTransferProducts(e.target.value)}
                  onBlur={(e) => {
                    newFormData = {
                      ...newFormData,
                      otherTransferProducts: e.target.value,
                    };
                    if (e.target.value === "") {
                      delete newFormData.otherTransferProducts;
                    }
                    props.onChange(newFormData);
                    setOtherTransferProducts(e.target.value);
                  }}
                />
                <Title
                  error={errorNames.includes("otherTransferCurrency")}
                >
                  I hvilken valuta (eks. EUR, USD) vil betalinger bli utført?
                </Title>
                <Input
                  value={otherTransferCurrency}
                  onChange={(e) => setOtherTransferCurrency(e.target.value)}
                  onBlur={(e) => {
                    newFormData = {
                      ...newFormData,
                      otherTransferCurrency: e.target.value,
                    };
                    if (e.target.value === "") {
                      delete newFormData.otherTransferCurrency;
                    }
                    props.onChange(newFormData);
                    setOtherTransferCurrency(e.target.value);
                  }}
                />
              </AdditionalQuestionsOther>
            )}
          </MultiSelectFrame>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const ErrorText = styled.p`
  color: #ff0000;
`

const Title = styled(FontBase)<{ error: boolean }>`
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => {
    return props.error ? "#ff0000" : "inherit";
  }};

  @media (max-width: 700px), (max-height: 700px) {
    font-size: 14px;
  }
`;

const AdditionalQuestionsOther = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px; /* Space from checkboxes */
  grid-column: span 2; /* Ensures it spans both columns */
`;

const Input = styled.textarea`
  flex-grow: 3;
  height: 60px;
  font-size: 16px;
  border: 1px solid #cccccc;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 80%;
  resize: vertical;
  min-height: 60px;
  &:focus {
    background-color: #ffffff;
  }
`;

const MultiSelectFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px; /* Adjust spacing between columns */
  row-gap: 10px; /* Space out rows */
  margin-left: 30px;
  align-items: start; /* Align items to the top */
`;

const StyledCheckbox = styled.input`
  appearance: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  border-radius: 6px;
  border: 3.58px solid ${PINK};
  padding: 0;
  margin: 0;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  margin-right: 10px;

  &:checked {
    background-color: ${PINK};
  }
`;
export default ZtlCustomCountryPicker;
