import { Route, Switch, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import axios from "axios";

import Header from "./components/header";
import Onboarding from "./components/onboarding";
import ErrorHandler from "./components/error-handler";
import LanguageSelector from "./components/language-selector";
import { throwError } from "./store/error/actions";

import { SNOW, RED, PINK } from "./styles/colors";
import { Text } from "./styles/fonts";
import InboxSpinner from "./icons/InboxSpinner";

import ZtlIcon from "./icons/ztl-icon";

const App = withTranslation()((props) => {
  let location = useLocation();
  const [loading, setLoading] = useState(true);
  const [flowId, setFlowId] = useState("");
  const [flowData, setFlowData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const url = location.pathname;
      if (url.split("/").length < 3) {
        setLoading(false);
        return;
      }
      try {
        const flowId = url.split("/")[2];
        const { data } = await axios.get(`/api/onboarding/${flowId}/status`, {
          headers: { "Cache-Control": "no-cache" },
        });
        setFlowData(data);
        setFlowId(flowId);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response.status === 404)
          return props.throwError("not-found-error");
        props.throwError("generic-error");
      }
    };
    fetchData();
  }, []);

  return loading ? (
    <AppFrame style={{ justifyContent: "center" }}>
      <InboxSpinner />
    </AppFrame>
  ) : (
    <AppFrame>
      <Header>
        <ZtlCircle>
          <ZtlIcon />
        </ZtlCircle>
        <OptionFrame
          style={{ alignSelf: "flex-end", marginRight: 20, height: 40 }}
        >
          <Route component={LanguageSelector} />
        </OptionFrame>
      </Header>
      <ContentViewer>
        {props.error.display ? (
          <ErrorHandler />
        ) : (
          <Switch>
            <Route path="/onboarding/:flowId" component={Onboarding} />
            <Route
              render={() => <Text style={{ marginTop: 50 }}>link-erp</Text>}
            />
          </Switch>
        )}
        {props.error.errorData.mainText !== "Takk!" &&
        props.error.errorData.mainText !== "Already onboarded!" &&
        flowData.status !== "archived" ? (
          <Route
            path="/onboarding/:flowId"
            render={() => (
              <Footer>
                <Text>Har du gjort noe feil?</Text>
                <CancellationButton
                  onClick={() =>
                    abortOnboardingProcess(flowId, props.throwError, flowData)
                  }
                >
                  <Text>Avbryt og begynn på nytt i ditt økonomisystem</Text>
                </CancellationButton>
              </Footer>
            )}
          />
        ) : null}
      </ContentViewer>
    </AppFrame>
  );
});

/**
 *
 * @param {string} flowId
 * @param {*} throwError - Throws global applicaiton error(redux)
 */
async function abortOnboardingProcess(flowId, throwError, flowData) {
  try {
    const { status } = await axios.delete(`/api/onboarding/${flowId}`);
    if (status === 200 && flowData.redirectUrl) {
      window.location.replace(flowData.redirectUrl);
    }
  } catch (err) {
    console.error(err);
    throwError("generic-error", {
      redirectUrl: this.props.flow.redirectUrl || "",
    });
  }
}

const CancellationButton = styled.button`
  p {
    color: ${PINK};
    text-align: left;
  }
  border: none;
  background-color: inherit;
  cursor: pointer;
  margin-left: -6px;
`;

const Footer = styled.footer`
  margin-bottom: 80px;
  width: 100%;
  margin-top: 50px;
`;

const ContentViewer = styled.div`
  height: auto;
  width: 70%;

  min-width: 600px;
  background-color: ${SNOW};
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  max-width: 960px;

  @media (max-width: 700px), (max-height: 700px) {
    min-height: 100%;
    width: 100%;
    min-height: unset;
    min-width: unset;
    flex-grow: 1;
    margin-bottom: 0;
  }
`;

const AppFrame = styled.div`
  height: auto;
  min-height: 100%;
  min-width: 300px;
  width: 100%;
  background-color: ${SNOW};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  padding: 0px 0 0 20px;
  position: relative;
`;

const ZtlCircle = styled.div`
  height: 50px;
  width: 80px;
  background-color: ${RED};
  position: absolute;
  padding-left: 10px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: flex-start;

  @supports not (align-self: flex-start) {
    position: static;
    margin-left: -300px;
  }

  @media (max-width: 700px), (max-height: 700px) {
    transform: scale(0.7);
    margin-left: 0px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const OptionFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const mapDispatchToProps = (dispatch) => {
  return {
    throwError: (error) => dispatch(throwError(error)),
  };
};

const mapStateToProps = ({ error }) => {
  return { error };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
