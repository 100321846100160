import React, { useState } from "react";
import styled from "styled-components";
import { LIGHTGRAY } from "../../../styles/colors";

import { JSONSchema6 } from "json-schema";
import { FieldProps } from "react-jsonschema-form";
import { getI18n } from "react-i18next";

import PhoneInput, { PhoneInputProps } from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import nb from "react-phone-number-input/locale/nb.json"
import en from "react-phone-number-input/locale/en.json"


interface CustomPhoneInputSchema extends JSONSchema6 {
  width: string;
  length: number;
  pattern?: any;
  hints?: Array<string>;
  locked?: boolean;
}

interface CustomPhoneInputProps extends FieldProps {
  schema: CustomPhoneInputSchema;
  language: string;
}

const CustomPhoneInput = (props: CustomPhoneInputProps) => {
  const [value, setValue] = useState("")
  
  const {language} = getI18n()
  const translations = language.toLowerCase() === "nb" ? nb : en
  
  const {pattern} = props.schema
  const isValid = pattern && value ? (value.match(pattern) || []).some(v => v === value) : value ? value.length > 0 : false;
  
  return (
    <StyledPhoneInput
      inputClassName={isValid ? "valid" : "invalid"}
      value={value}
      labels={translations}
      onChange={e => handleInputChange(e, props.onChange, setValue)}
      disabled={props.schema.locked ? true : false}
      defaultCountry="NO"
    />
  )
}

// @ts-ignore
function handleInputChange(
  e: string,
  onChange: (val: string) => void,
  setValue: React.Dispatch<React.SetStateAction<string>>
) {
  setValue(e);
  onChange(e);
}


const StyledPhoneInput = styled(PhoneInput)<PhoneInputProps>`
  width: 60%;
  height: 40px;

  input[type=tel] {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    outline: none;
    height: 90%;
    border: 1px solid #b8b8b8;
    background-color: ${props => {
      return props.inputClassName !== undefined && props.inputClassName === "valid" ? LIGHTGRAY : "#FFFFF"
    }}
  }
`

export default CustomPhoneInput;